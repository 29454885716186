import React from 'react'
import { graphql } from 'gatsby'

import Footer from '../components/PageSection/Footer'
import LanguageSelector from '../components/LanguageSelector'
import Header from '../components/PageSection/Header'
import Seo from '../components/PageSection/Seo'
import Rooms from '../components/Rooms/Rooms'

const RoomPage = () => {

  return <div id="wrapper">

    <Header page={'rooms'} />

    <Seo />

    <div id={'content'} />

    <Rooms />

    <Footer page={'rooms'} />

    <LanguageSelector />
  </div>
}

export default RoomPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

import React, {Fragment, FunctionComponent} from 'react'
import {useTranslation, Link} from 'gatsby-plugin-react-i18next'
import {Link as ScrollLink, animateScroll} from 'react-scroll'
import Img from "gatsby-image"

import SectionHeader from '../Section/SectionHeader'
import Section from '../Section/Section'

import BookButton from '../BookButton'

type IProps = {
    name: string,
    images: any[]
}

const Room: FunctionComponent<IProps> = ({name, images}) => {
    const { t } = useTranslation()

    const rooms = ['standard', 'comfort', 'deluxe', 'triple', 'quadruple', 'family']

    const id          = `room${name.charAt(0).toUpperCase()}`
    const title       = t('rooms.' + name + '.title')
    const description = t('rooms.' + name + '.description')

    return <Section id={id}>
        <SectionHeader title={title}>
            <div dangerouslySetInnerHTML={{__html:description}} />
        </SectionHeader>

        <div className={'gallery'}>
            {
                images.map((image, index) => {
                    return <a className={`image ${((index + 1) % 3) === 0 ? 'landscape': 'portrait'}`} key={`room${name}image${index}`}>
                        <Img fluid={image} alt={`${title} #${index + 1}`} />
                    </a>
                })
            }
        </div>

        <ul className="actions" style={{flexWrap: 'wrap', marginTop: '1rem'}}>
            {
                rooms.map((room, index) => {
                    if (room === name) {
                        return null
                    }

                    return <Fragment key={`room${name}link${room}`}>
                        <li>
                            <ScrollLink to={`room${room.charAt(0).toUpperCase()}`} smooth={true} duration={500}>
                                <span className="label">{t('rooms.' + room + '.title')}</span>
                            </ScrollLink>
                        </li>
                        {index + 1 === rooms.length ? null : <li className={'separator'}>|</li>}
                    </Fragment>
                })
            }
        </ul>

        <Link to={'/'} className={'button'} style={{marginRight: '1em'}}>&laquo; {t('back_button')}</Link>
        <BookButton />
    </Section>
}

export default Room
